td.greenStatus {
  background-color: lawngreen;
}

td.redStatus {
  background-color: #ff0000;
}

td.amberStatus {
  background-color: #ffff66;
}

td.beigeStatus {
  background-color: burlywood;
}

td.blueStatus {
  background-color: dodgerblue;
}

td.greyStatus {
  background-color: grey;
}

td.blackStatus {
  background-color: black;
}

td.pinkStatus {
  background-color: #FF33F0;
}
