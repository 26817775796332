@-webkit-keyframes rotatingSpinner {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotatingSpinner {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loadingAnimation {
  -webkit-animation: rotatingSpinner 1s linear infinite;
  -moz-animation: rotatingSpinner 1s linear infinite;
  -ms-animation: rotatingSpinner 1s linear infinite;
  -o-animation: rotatingSpinner 1s linear infinite;
  animation: rotatingSpinner 1s linear infinite;
}
