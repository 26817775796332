.order .totals {
  float: right;
  text-align: right;
}

.order .address {
  line-height: 1.25;
  display: inline;
}

.order .content-header {
  margin-bottom: 20px;
}
.order .dropdown-menu {
  padding: 0px;
}
.order .dropdown-menu .divider {
  margin: 0px;
}
.order .content-header .h2 {
  margin-top: 0px;
}
.order-tabs a[role="tab"] {
  outline-style: none;
}
.order-header {
  position: relative;
  display: block;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.order-header .img-polaroid {
  position: absolute;
  bottom: -20px;
  left: 20px;
  border-radius: 100%;
  width: 80px;
  height: 80px;
  z-index: 900;
  max-width: 100%;
  vertical-align: middle;
  border: 0;
}
.order-header .order-bg-box {
  position: relative;
  height: 120px;
  overflow: hidden;
}
.order-header .order-bg-box .order-bg {
  overflow: hidden;
  position: absolute;
  top: -300%;
  left: 0;
  width: 100%;
  -webkit-filter: blur(50px);
  opacity: .5;
}
.order-header .order-bg-box .box-content {
  position: absolute;
  background: rgba(255,255,255,0.3);
  height: 100%;
  width: 100%;
  z-index: 100%;
  overflow: hidden;
}
.order-header .order-bg-box .box-content .back-button {
  position: absolute;
  top: 10px;
  left: 10px;
}
.order-header .order-bg-box .box-content .login-count {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 11px;
}
.order-header .order-bg-box .box-content .lined-text {
  font-size: 11px;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  padding-bottom: 2px;
}
.order-header .order-bg-box .box-content .ordername-area {
  position: absolute;
  left: 120px;
  bottom: 5px;
}
.order-header .order-bg-box .box-content .ordername-area h4 {
  font-weight: 200;
  font-size: 34px;
  margin: 0;
}
.order-header .order-bg-box .box-content .ordername-area .name {
  margin-right: 10px;
}
.order-header .order-bg-box .box-content .ordername-area .order-label {
  color: #000;
  font-size: 10px;
  font-weight: normal;
  text-transform: lowercase;
  padding: 5px 10px;
  background: rgba(255,255,255,0.5);
  border-radius: 3px;
  position: relative;
  bottom: 5px;
}

.amount-due {
  background-color: #faed8c;
}
