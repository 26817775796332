pre.json-object {
   background-color: #fbfbfb;
   border: 1px solid #f1f1f1;
   border-radius: 0px;
   padding: 10px 20px;
   font-size: 12px;
}
.json-object .json-key {
   color: #16214D;
}
.json-object .json-value {
   color: #01B48F;
}
.json-object .json-string {
   color: #EB5424;
}
