header.dashboard-header {
  background-color: #fbfbfb;
}

header.dashboard-header nav .navbar-header {
  width: 800px;
}

header.dashboard-header nav .navbar-brand {
  font-weight: normal;
  padding: 12px 150px;
  width: 100%;
  min-width: 400px;
}

header.dashboard-header nav #header {
  background-size: 100px 40px;
  background-image: url(images/1aa2a6abc60720f311b8e88a683670b8.png);
  background-position: center left;
  background-repeat: no-repeat;
}

.btn-nav span {
  font-size: 16px;
  top: 2px;
  position: relative;
}
